import React, { useState } from 'react'
import mobile from '../../../assets/images/mobile2x.png'
import googlePlay from '../../../assets/images/googlePlay.png'
import appleIOS from '../../../assets/images/appledownload.png'
import elred from '../../../assets/images/el.png'
import { Offcanvas, Spinner } from 'react-bootstrap'
import { openPlaystore } from '../../../globalFunctions'
import Skeleton from 'react-loading-skeleton'

const AlreadyAccountPopup = ({ show, handleClose }) => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const [isLoading, setIsLoading] = useState(true)
    return (
        <Offcanvas show={show} onHide={handleClose} placement='bottom' className='offerings_screen'>
            <div className='main_div_canvas'>
                <div className='top_canvas_section'>
                    <span className='skipOfferings' onClick={() => {
                        handleClose();
                        setIsLoading(true);
                    }}>Skip</span>
                    <hr id='popup_hr' />
                    <img src={elred} alt="" />
                </div>
                <div className="wallpaper">
                    <img src={mobile} alt="" onLoad={() => setIsLoading(false)} className={!isLoading ? 'd-block' : 'd-none'} />
                    {/* <Skeleton height={380} width={300} baseColor='#D6DAE5' style={{ marginTop: '100px', zIndex: "0", borderRadius: "40px 40px 0 0"}} /> */}
                    <Spinner className={isLoading ? 'd-block' : 'd-none'} />
                </div>
                <div className='content_div_canvas'>
                    <div className='txt1'>
                        An account with the registered email/phone already exists.
                    </div>
                    <div className="description">
                        Please download the elRED app to get the best experience.
                    </div>
                    {/* <img src={isIOS ? appleIOS : googlePlay} alt="" onClick={openPlaystore} /> */}
                    <img src={isIOS ? appleIOS : googlePlay} alt="" />
                </div>
            </div>
        </Offcanvas>
    )
}

export default AlreadyAccountPopup
