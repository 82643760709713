import React from 'react'
import LeadscardBottom from './LeadscardBottom'
import LeadsCardProfile from './LeadsCardProfile'
import moment from 'moment'
import noImageLeads from '../../../../../../../../assets/images/leads-no-img-background.png'
function LeadsCard({ data, setShowVerifiedPopup }) {


  return (
    <div className="leads-reply-card">
      <div
        className="card_wrapper"
        style={data?.backgroundImages.length > 0 ? {
          "background": ` linear-gradient(0deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.35) 50%), url(${data?.backgroundImages?.[0]}),no-repeat`
        } : { "background": `url(${noImageLeads})` }}
      >
        <LeadsCardProfile data={data} setShowVerifiedPopup={setShowVerifiedPopup} />
      
          <div className="profile-card-border-bottom"></div>
          <div className='leadscreatedDate' >{`Created on ${moment(data?.leadCreatedAt).format('ddd, DD MMM YYYY')}`}</div>
          <LeadscardBottom data={data} />
   

      </div>

    </div>
  )
}

export default LeadsCard
