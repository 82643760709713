import React, { useEffect, useRef, useState } from "react";
import "./testimonials.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useResponse } from "../../../hooks/useResponse";
import ProfileTestimonalCard from "../../../../UmeshModule/components/card/ProfileTestimonalCard";
import TestimonalShimmer from "../../../../UmeshModule/components/shimmer/TestimonalShimmer";
import classNames from "classnames";

const Testimonials = ({ isLive, productionUrl, rgba, baseColor }) => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const userCode = searchParams.get("userCode");
  const scrollRef = useRef(null)
  const [isDragging, setIsDragging] = useState(false);
  const [startPosition, setStartPosition] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0);
  const { data, loading } = useResponse(`${isLive ? productionUrl : ""}/noSessionPreviewTestimonials?userCode=${userCode}&start=1&offset=10`);
  
  useEffect(()=>{
    const handleMouseUp = (e) => {
      setIsDragging(false)
    };
    document.addEventListener("mouseup", handleMouseUp)
    return()=>{
      document.removeEventListener("mouseup", handleMouseUp)
    }
  },[])

  const handleMouseMove = (e) => {
    if(!isDragging) return;
    const deltaX = e.clientX - startPosition;
    scrollRef.current.scrollLeft = scrollLeft - deltaX;
    e.stopPropagation()
  }

  return (
    <div style={{ backgroundColor: rgba }} className="testimonials">
      <div className=" d-flex justify-content-between align-items-centre">
        <p className=" mb-0">Testimonials</p>
        {loading || !data.length ? null : (
          <div className="btn-see-more testi_btn_see_more" onClick={() => navigate(`/testimonials?userCode=${userCode}`)} > See all </div>)} </div>
      {loading ? (
        <TestimonalShimmer color={rgba} showCard={false} baseColor={baseColor} />
      ) :data.length ? (
        <div ref={scrollRef} onMouseMove={handleMouseMove}
        className={classNames("testimonal-hor-container", {signle_testimonal_container: data?.length === 1, })}>
        {data?.map((item, index) => {
          const isLastIndex = index !== 0 && index === data.length-1
          return (
            <div style={{paddingRight:isLastIndex?"16px":"0px"}} key={item?.testimonialId}>
              <ProfileTestimonalCard
                onClick={(e) => {
                  
                  navigate(`/testimonials/details?userCode=${userCode}`, {
                    state: item, })
                    e.stopPropagation();
                  }}
                isProfileCard={true} item={item} index={index} /> </div>);})} </div>
      ) : (<div className="no-comment no-testimonials text-align-center"> No Testimonials available yet</div> )}
    </div>
  );
};
export default Testimonials;
