import { useLocation } from "react-router-dom";
import "./profile-titles-popup.scss";
import { useEffect } from "react";
import crossImg from "../../../../assets/images/crossredRedRoundBg.svg";
import briefcaseImg from "../../../../assets/images/briefcaseRedBg.svg";
import { clearToasts } from "react-simple-toasts";

const ProfileTitlesPopup = ({ showTitlePopup, setShowTitlesPopup, titlesArray }) => {
    const location = useLocation()
    useEffect(() => {
        setShowTitlesPopup(false);
    }, [location])

    useEffect(() => {
        if (showTitlePopup) clearToasts();
    }, [showTitlePopup]);

    return (
        <div className="container-titles-popup">
            <div className={showTitlePopup ? "profile-titles-container" : "profile-titles-container-hidden"}>
                <div className="titles-popup-top-header">
                    <span className="titles-popup-top-text-left">Title</span>
                    <span className="titles-popup-top-close-img" onClick={() => setShowTitlesPopup(false)}><img src={crossImg} alt=""/></span>
                </div>
                <div className="titles-popup-content">
                    <div className="titles-popup-content-image"><img src={briefcaseImg} alt="" className="titles-popup-briefcase-img" /></div>
                    <div className="titles-popup-titles-text">
                        {titlesArray?.map((title, index) => 
                            <span key={title._id} className="titles-popup-title">{index === titlesArray?.length - 1 ? title?.value : title?.value + " | "}</span>)}
                    </div>
                </div>
            </div>
        <div onClick={() => setShowTitlesPopup(false)} className={showTitlePopup ? "titles-popup-overlay" : ""}></div>
    </div>
    )
}

export default ProfileTitlesPopup;