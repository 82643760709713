import React, { lazy } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import CommentsCard from "../../components/comments/CommentsCard";
import CommentsShimmer from "../../components/shimmer/CommentsShimmer";
import RedLoader from "../../../TashafsModule/Profile/components/RedLoader/RedLoader";
import classNames from "classnames";
const AadhaarVerifiedPopup = lazy(()=> import("../../../TashafsModule/Profile/components/AadhaarVerifiedPopup/AadhaarVerifiedPopup"));

function CommentsScroll({
  hasMore,
  data,
  getData,
  loading,
  showMoreReplies,
  onClikShowMore,
  showVerifiedPopup,
  setShowVerifiedPopup,replyLoader
}) {
  return (
    <>
    <div id="comments-body" className={classNames("comments-body", {"screen-cont":!loading,"disabled-scroll":loading})}>
      <InfiniteScroll
        dataLength={data?.length}
        next={getData}
        style={{ overflow: "hidden" }}
        scrollableTarget="comments-body"
        hasMore={hasMore}
      >
        {loading ? (
          <CommentsShimmer profile={false} />
        ) : !data.length ? (
          <div className="no-comments" style={{ color: "#00000099" }}>
            No Comments available yet
          </div>
        ) : (
          data.map((item, index) => {
            return (
              <CommentsCard
                key={`comment-${index}`}
                item={item}
                index={index}
                showMoreReplies={showMoreReplies}
                onClikShowMore={onClikShowMore}
                setShowVerifiedPopup={setShowVerifiedPopup}
                replyLoader={replyLoader}
              />
            );
          })
        )}
        {hasMore  ? <RedLoader /> : null}
      </InfiniteScroll>
    </div>
    <AadhaarVerifiedPopup showVerifiedPopup={showVerifiedPopup} setShowVerifiedPopup={setShowVerifiedPopup} />
    </>
  );
}

export default CommentsScroll;
