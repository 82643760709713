import React, { useState } from "react";
import greentick from "../../../../assets/images/greentick.svg";
import bluetick from "../../../../assets/images/bluetick.svg";
import { calcTextLength } from "../../../../globalFunctions";
import { useNavigate, useSearchParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

const WorkDetail = ({ title, name, city, startYear, endYear, last, currentlyDoing, isVerified, greenTickVerification, key, educationCertificatePreview, educationCertificateURL }) => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const userCode = searchParams?.get("userCode");
  const [eduCertificateLoader, setEduCertificateLoader] = useState(true);

  const navigateToEduCertificate = () => {
    navigate(`/my-bio/view-education-certificate/?userCode=${userCode}`, {
    state: { educationCertificatePreview, educationCertificateURL, userCode }})
  }

  return (
    <div className="work-detail" key={key}>
      <div className="work-title-div">
         <div className="work-title">{title}</div>
        {greenTickVerification &&  <div className="work-verify">
          <img src={greentick} alt="" />
        </div>}
      </div>
      <div className="work-desc-div">
        <div className="name">
          {calcTextLength(name.length, name)}
        </div>
        {isVerified &&  <div className="badge1">
         <img src={bluetick} alt="" />
        </div>}
        <div className="city"> | {calcTextLength(city.length, city)}</div>
      </div>
      <div className={educationCertificatePreview ? "year-with-edu-cert" : "year"}>
        {startYear.trim() !== "" && (endYear.trim() !== "" || currentlyDoing)
          ? `${startYear.trim()} - ${
              currentlyDoing ? "Present" : endYear.trim()
            }`
          : startYear.trim() !== ""
          ? startYear.trim()
          : startYear.trim() === "" && currentlyDoing
          ? "Present"
          : endYear.trim()}
      </div>
      <div className={educationCertificatePreview && eduCertificateLoader ? "edu-certificate-shimmer" : "d-none"}><Skeleton width={60} height={80} /></div>
      {educationCertificatePreview && <div onClick={navigateToEduCertificate} className={eduCertificateLoader ? "d-none" : "edu-certificate-preview-img"}>
        <img src={educationCertificatePreview} alt="" onLoad={() => setEduCertificateLoader(false)} />
      </div>}
      {last ? null : <hr />}
    </div>
  );
};

export default WorkDetail;
