import React from 'react'
import RedLoader from '../../../../Profile/components/RedLoader/RedLoader'
import InfiniteScroll from 'react-infinite-scroll-component'
import { calcTextLength } from '../../../../../globalFunctions'
import SingleAwardChip from './SingleAwardChip'

function AllAwardData({data,fetchMoreData,hasMore,searchHasMore,paginateLoader,viewAward,userCode,navigate,bluetick}) {
      return (
    <>
        <InfiniteScroll
                        dataLength={data.length}
                        next={fetchMoreData}
                        hasMore={hasMore||searchHasMore}
                        loader={paginateLoader&&<RedLoader />}
                        height={data.length % 10 === 0 ? "840px" : "93vh"}
                        className="awards-scrolling" >
                            
                        {data?.map((item, id) => (
                            <>
                            <SingleAwardChip viewAward={viewAward} item={item} userCode={userCode} navigate={navigate}
                             calcTextLength={calcTextLength} bluetick={bluetick} data={data} id={id}/>
                            </>
                        ))}
                    </InfiniteScroll>
    </>
  )
}

export default AllAwardData
